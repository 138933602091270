import useRequest, { CommonResponse } from "./../useRequest";
import { computed } from "vue";

const useClient = (client?) => {
  const { data, ...other } = useRequest<CommonResponse<any>>(
    `/app/enum/client`, { client: client }
  );
  const enhancedData = computed(() => {
    if (!data.value) return null;
    return data.value.data.data;
  });

  return {
    client: enhancedData,
    ...other
  };
};

export default useClient;
