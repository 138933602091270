
import { defineComponent, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { translate } from "@/core/helpers/translate";
import {
  showErrorPopup,
  showSuccessPopup,
  inputNumbersOnly,
  useQuery,
} from "@/core/helpers/common-helper";
import ApiService from "@/core/services/ApiService";
import { setNewPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import useGateway from "@/core/services/compositions/enum/useGateway";
import useChannelByHash from "@/core/services/compositions/enum/useChannelByHash";
import useBillType from "@/core/services/compositions/enum/useBillType";
import useAdminScheme from "@/core/services/compositions/enum/useAdminScheme";
import useClient from "@/core/services/compositions/enum/useClient";

interface Institution {
  client_id: string;
  name: string;
  address: string;
  phone: string;
  email: string;
  gateway: string;
  channels: Array<any>;
}

export default defineComponent({
  setup() {
    // init
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const { query } = useQuery();
    const submitButton = ref<null | HTMLButtonElement>(null);
    const { gateway } = useGateway();
    const { channel } = useChannelByHash();
    const { billType } = useBillType();
    const { adminScheme } = useAdminScheme();
    const clientHash = query.value.client || null;
    const { client, isValidating } = useClient(clientHash);

    // breadcrumb
    setNewPageBreadcrumbs(t("management.institution.create"), [
      { title: t("management.institution.create") }
    ]);

    const create = reactive<Institution>({
      client_id: clientHash,
      name: '',
      address: '',
      phone: '',
      email: '',
      gateway: '',
      channels: [
        {
          channel_id: '',
          bcn: '',
          bcn_type: '',
          admin_fee: '',
          admin_fee_shared: '',
          sponsor_fee_shared: '',
          admin_scheme: '',
        }
      ]
    });

    const addChannel = () => {
      create.channels.push(
        {
          channel_id: '',
          bcn: '',
          bcn_type: '',
          admin_fee: '',
          admin_fee_shared: '',
          sponsor_fee_shared: '',
          admin_scheme: '',
        }
      );
    }

    const resetForm = () => {
      create.client_id = '';
      create.name = '';
      create.address = '';
      create.phone = '';
      create.email = '';
      create.gateway = '';
      create.channels = [
        {
          channel_id: '',
          bcn: '',
          bcn_type: '',
          admin_fee: '',
          admin_fee_shared: '',
          sponsor_fee_shared: '',
          admin_scheme: '',
        }
      ];
    }

    const submitForm = () => {
      if (create.name == '') {
        showErrorPopup({
          title: 'Error',
          text: 'Name cannot empty',
          message: 'Name cannot empty',
        })

        return;
      }

      submitButton.value ? (submitButton.value.disabled = true) : null;
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      const payload = {
        client_id: create.client_id,
        name: create.name,
        address: create.address,
        phone: create.phone,
        email: create.email,
        gateway: create.gateway,
        channels: create.channels
      };

      ApiService.setHeader();
      ApiService.post(
        `app/entity/institution`,
        payload as any
      )
        .then((data) => {
          showSuccessPopup({
            title: 'Status',
            text: 'Success'
          });

          resetForm();

          // Swal.fire({
          //   showClass: {
          //     popup: "mumtaz-success-popup"
          //   },
          //   icon: "success",
          //   title: 'Success',
          //   text: 'Continue to create new account?',
          //   showDenyButton: true,
          //   confirmButtonText: 'Yes',
          // }).then((result) => {
          //   if (checkPermission('institution-create').isGranted) {
          //     if (result.isConfirmed) {
          //       router.push({ name: "app.management.institution.create", query: { client: data.data.data.id } });
          //     } else {
          //       resetForm();
          //     }
          //   } else {
          //     showSuccessPopup({
          //       title: 'Status',
          //       text: 'Success'
          //     });

          //     resetForm();
          //   }
          // })
        })
        .catch(({ response }) => {
          if (Object.prototype.hasOwnProperty.call(response.data, "data")) {
            if (response.data.data.length > 0) {
              for (const key in response.data.data) {
                showErrorPopup({
                  title: response.data.rc,
                  text: response.data.data[key],
                  message: response.data.data[key],
                })
              }
            } else {
              showErrorPopup({
                title: response.data.rc,
                text: response.data.message,
                message: response.data.message,
              })
            }
          } else {
            showErrorPopup({
              title: response.data.rc,
              text: response.data.message,
              message: response.data.message,
            })
          }
        })
        .finally(() => {
          submitButton.value ? (submitButton.value.disabled = false) : null;
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };


    return {
      create,
      translate,
      submitForm,
      submitButton,
      resetForm,
      gateway,
      channel,
      billType,
      adminScheme,
      inputNumbersOnly,
      addChannel,
      client,
      isValidating,
      clientHash
    };
  },
  methods: {}
});
